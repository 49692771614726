'use strict';

/**
 * DOM Ready alternative
 */
export const interactive = () => {
  return new Promise((resolve) => {
    if (document.readyState !== 'loading') {
      resolve();
    }
    else {
      document.addEventListener('readystatechange', () => {
        if (document.readyState === 'interactive') {
          resolve();
        }
      });
    }
  });
};
