/**
 * Utility function for Promise.allSettled
 *
 * @param {Array<Promise>} promises - array of promises to check
 * @return {Promise<void>} promise - result promise
 */
export function settled(promises = []) {
  if (Promise.allSettled) {
    return Promise.allSettled(promises);
  }

  // we don't care about errors here, but we must catch them
  return Promise.all(promises).catch(() => Promise.resolve());
}
