import { interactive } from './interactive.mjs';

/**
 * Init sticky element for browsers
 * that does not support natively position sticky from css
 */

/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

function loadStickyElementScript() {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    const url = 'https://d6vtbcy3ong79.cloudfront.net/sticky-element/2.0.14/index.js';

    script.src = url;
    script.async = true;

    script.addEventListener('load', resolve);
    script.addEventListener('error', reject);

    document.body.appendChild(script);
  });
}

function isPositionStickySupported() {
  const testNode = document.createElement('div');
  const prefixes = ['', '-webkit-', '-moz-', '-ms-'];

  return prefixes.some((prefix) => {
    try {
      testNode.style.position = `${prefix}sticky`;
    }
    catch (error) {
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    }

    return testNode.style.position !== '';
  });
}

/**
 * @param {Object} options - sticky element init options
 */
export function loadAndInitStickyElementIfNeeded(options) {
  if (isPositionStickySupported()) {
    // Position sticky is supported, no need to load StickyElement js
    return;
  }

  const initSticky = (stickyOptions) => {
    const stickyElement = window.StickyElement.create(stickyOptions);

    document.addEventListener('tk-update-sticky', () => {
      stickyElement.reset();
      stickyElement.update();
    });

    // usage
    // document.dispatchEvent(new CustomEvent('tk-update-sticky'));

    return stickyElement;
  };

  interactive().then(() => {
    if (window.StickyElement) {
      initSticky(options);
    }
    else {
      loadStickyElementScript()
        .then(() => initSticky(options))
        .catch((error) => console.error(`StickyElement script did not load. ${error.message}`));
    }
  });
}
