import Api from './api.mjs';
import { setSelectedState } from './set-selected-state.mjs';

export default class Account {
  constructor() {
    this.api = new Api();
    this.hiddenClassName = 'TK-dn';

    /* profile menu */
    this.logoutLink = document.querySelector('#js-tlrk-nav-log-out');
    this.avatarImage = document.querySelector('#js-tlrk-nav-avatar-image');
    this.authenticatedContainer = document.querySelector('#js-tlrk-nav-auth-container');
    this.notAuthenticatedContainer = document.querySelector('#js-tlrk-nav-not-auth-container');

    /* support menu */
    this.supportMenu = document.querySelector('#js-tlrk-nav-ya-support-menu');

    /* products container and products menu */
    this.productsMenuContainer = document.querySelector('#js-tlrk-nav-ya-products-menu');
    this.productsMenu = document.querySelector('#js-tlrk-nav-ya-products-menu .TK-Dropdown-List'); // First in double dropdown

    this.isYourAccount = !!(this.supportMenu && this.productsMenu);
    this.isClientSideAuth = !!(this.authenticatedContainer && this.notAuthenticatedContainer);

    /* binder */
    this.setSignoutUrl = this.setSignoutUrl.bind(this);
  }

  /**
   * Profile Menu
   * @param {String} email - if user email is given user is logged in
   */
  populateProfileMenu(email) {
    if (!email || !this.api.env || !this.isClientSideAuth) {
      return Promise.resolve(false);
    }

    const { signoutUrl } = this.api.env; // already double encoded

    return this.api.getUserAvatarUrl(email).then((avatarUrl) => {
      if (!avatarUrl) return;

      this.avatarImage && this.avatarImage.setAttribute('src', avatarUrl);
      this.logoutLink && this.logoutLink.setAttribute('href', signoutUrl);

      this.authenticatedContainer && this.authenticatedContainer.classList.remove(this.hiddenClassName);
      this.notAuthenticatedContainer && this.notAuthenticatedContainer.classList.add(this.hiddenClassName);
    });
  }

  /**
   * Support Menu
   * @param {String} email - if user email is given user is logged in
   */
  populateSupportMenu(email) {
    if (!email || !this.isYourAccount) {
      return Promise.resolve(false);
    }

    return this.api
      .getVirtualClassRoomSupport()
      .then((support) => {
        if (support) {
          const newItem = '<li><a href="/account/support/virtual-classroom" class="TK-Dropdown-Link" data-match-exact-path>On-Demand Product Training</li>';
          this.supportMenu.classList.add('TK-Dropdown--230');
          this.supportMenu.insertAdjacentHTML('beforeend', newItem);
        }
      });
  }

  /**
   * Products Menu
   * @param {String} email - if user email is given user is logged in
   */
  populateProductsMenu(email) {
    if (!email || !this.isYourAccount) {
      return Promise.resolve(false);
    }

    return this.api.getMySkuCategories().then((categories) => {
      let html = '';

      if (!categories.length) {
        html = `
          <li>
            <a href="/purchase.aspx" class="TK-Dropdown-List-Link TK-Nav-Ya-No-Products" data-match-exact-path>
              <span>There are no purchased products</span>
              <span><b class="TK-vam">Buy Products</b><svg class="TK-vam" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>
              <span>Purchase Individual Products Or DevCraft Value Bundles</span>
            </a>
          </li>
        `.trim();
      }
      else {
        html = categories.map((category) => {
          let href;

          if (category.type === 'Main') href = `/account/your-products?skucid=${category.id}`;
          if (category.type === 'Extension') href = `/account/your-products/extensions?skucid=${category.id}`;

          return `<li><a href="${href}" class="TK-Dropdown-List-Link" data-match-exact-path data-match-contains-search>${category.name}</a></li>`;
        }).join('');
      }

      if (categories.length > 18) {
        this.productsMenuContainer.classList.add('TK-Dropdown--Full-Split');
      }

      this.productsMenu.innerHTML = html;
    });
  }

  /**
   * Rerender logout link on ng-location-changed handler
   */
  setSignoutUrl() {
    if (!this.api.env || !this.logoutLink) {
      return;
    }

    const currentHref = window.location.href;
    const doubleEncodedHref = encodeURIComponent(encodeURIComponent(currentHref));
    const { telerikWebsiteUrl, signoutBaseUrl } = this.api.env;
    const signoutUrl = `${telerikWebsiteUrl}${signoutBaseUrl}${doubleEncodedHref}`;

    this.logoutLink.setAttribute('href', signoutUrl);
  }
}

/**
 * Dispatch account ready event
 *
 * @param {String} email - value containg user email
 * @param {Boolean} isYourAccount - value indicating that user is in your account website
 */
function dispatchAccountReadyEvent(email = null, isYourAccount = false) {
  const event = new CustomEvent('tk-account-ready', {
    detail: {
      email,
      isYourAccount
    }
  });

  document.dispatchEvent(event);
}

/**
 *
 */
const DASH_ID = '#js-tlrk-nav-dash';
const DRAWER_ID = '#js-tlrk-nav-drawer';

/* eslint consistent-return: 0 */
export function initAccount() {
  const account = new Account();

  if (!account.api.available) return dispatchAccountReadyEvent(null, account.isYourAccount);
  if (!account.isClientSideAuth) return dispatchAccountReadyEvent(null, account.isYourAccount);

  const dash = document.querySelector(DASH_ID);
  const drawer = document.querySelector(DRAWER_ID);

  const initPromise = account.api.initAsync();
  const userEmailPromise = initPromise.then(() => account.api.getCurrentUserEmail());

  const handleSelectedState = () => {
    if (dash && drawer) {
      setSelectedState(dash);
      setSelectedState(drawer);
    }
  };

  const promises = [
    userEmailPromise.then((email) => account.populateProfileMenu(email)),
    userEmailPromise.then((email) => account.populateSupportMenu(email)),
    userEmailPromise.then((email) => account.populateProductsMenu(email))
  ];

  // IE Edge does not support Promise.prototype.finally ...
  const ready = () => {
    return userEmailPromise.then((email) => dispatchAccountReadyEvent(email, account.isYourAccount));
  };

  Promise
    .all(promises)
    .then(() => {
      handleSelectedState();
      ready();
    })
    .catch(() => {
      handleSelectedState();
      ready();
    });

  document.addEventListener('ng-location-changed', handleSelectedState);
  document.addEventListener('ng-location-changed', account.setSignoutUrl);
}
