// import env from '../../data/environments.js';
// import skus from '../../data/sku-categories.js';

const FETCH = !!window.fetch;
const PROMISE = !!window.Promise;

/* eslint quote-props: ["error", "consistent"] */
const defaults = {
  method: 'GET',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  credentials: 'include' // include cookies for cross-origin call
};

class Api {
  constructor(hostname = Api.getHostname(), _defaults = defaults) {
    this.defaults = Object.assign({}, _defaults);

    this.url = Api.getApiUrl(hostname);
    this.hostname = hostname;
    this.isStore = hostname.includes('store.progress.com');

    this.online = navigator.onLine;

    this.available = !!(this.url && this.online && PROMISE && FETCH);

    this.rejection = new Error('Not allowed domain || offline || no browser support');
  }

  /**
   * @param {Response} res
   * @return {Promise<JSON>} - a promise that resolves with the result of parsing the response body text as JSON
   */
  static json(res) {
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  /**
   * @return {String} current hostname
   */
  static getHostname() {
    return window.location.hostname.toLowerCase();
  }

  /**
   * @param {String} hostname
   * @return {String} API_URL
   */
  static getApiUrl(hostname = Api.getHostname()) {
    let API_URL = '';

    /* @DEVELOPMENT
    if (hostname === 'localhost') {
      return 'https://localhost:4042/api';
    }
    @DEVELOPMENT */

    const TLRK_DOMAINS = [
      'www.telerik.com',
      'docs.telerik.com',
      'demos.telerik.com',
    ];

    const PRGS_DOMAINS = [
      'www.progress.com',
      'store.progress.com'
    ];

    if (TLRK_DOMAINS.includes(hostname)) {
      API_URL = 'https://www.telerik.com/account/api';
    }
    else if (PRGS_DOMAINS.includes(hostname)) {
      API_URL = 'https://store.progress.com/api';
    }
    else {
      const divisions = hostname.split('.');
      const subdomain = divisions[0];
      const name = divisions[1];
      const tld = divisions[2];

      const len = subdomain.length;
      const tlrk = subdomain.startsWith('www') && (len >= 3 && len <= 6) && name === 'telerik' && tld === 'com';
      const prgs = subdomain.startsWith('www') && (len >= 3 && len <= 6) && name === 'progress' && tld === 'com';
      const store = subdomain.startsWith('store', 3) && len === 8 && name === 'progress' && tld === 'com';

      if (tlrk) {
        API_URL = `https://${subdomain}.telerik.com/account/api`;
      }

      if (prgs) {
        const prefix = hostname.substring(3, 6);
        API_URL = `https://${prefix}store.progress.com/api`;
      }

      if (store) {
        const prefix = hostname.substring(0, 3);
        API_URL = `https://${prefix}store.progress.com/api`;
      }
    }

    return API_URL;
  }

  /**
   * @return {Promise<Object>} - env data object
   */
  initAsync() {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    return this.getEnvironment().then((env) => {
      const currentHref = window.location.href;
      const doubleEncodedHref = encodeURIComponent(encodeURIComponent(currentHref));

      this.env = env;
      this.env.signoutUrl = `${env.telerikWebsiteUrl}${env.signoutBaseUrl}${doubleEncodedHref}`;

      return env;
    });
  }

  /**
   * @return {Promise<Object>} - env data object
   */
  getEnvironment() {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    const endpoint = this.isStore ? `${this.url}/v1/environments/current` : `${this.url}/v2/environments/current`;

    return fetch(endpoint, this.defaults).then(Api.json);
  }

  /**
   * @return {Promise<String>} - current user email
   */
  getCurrentUserEmail() {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    const endpoint = `${this.url}/v1/users/current/email`;

    return fetch(endpoint, this.defaults).then(Api.json);
  }

  /**
   * try to get current user email
   * if response is empty string reject with error
   *
   * @return {Promise<String>} - current user email - or rejects with error
   */
  tryGetCurrentUserEmail() {
    return this.getCurrentUserEmail().then((email) => {
      if (email) return email;
      return Promise.reject(new Error('User is not logged in'));
    });
  }

  /**
   * @param {String} - user email
   * @return {Promise<String>} - user avatar url
   */
  getUserAvatarUrl(email) {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    if (!email) {
      return Promise.reject(new Error('No email provided'));
    }

    const encoded = encodeURIComponent(email);
    const endpoint = `${this.url}/v1/users/${encoded}/avatarurl`;

    return fetch(endpoint, this.defaults).then(Api.json);
  }

  /**
   * @return {Promise<Boolean>} result - true/false
   */
  getLoggedInState() {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    const endpoint = `${this.url}/v1/users/current/logged`;

    return fetch(endpoint, this.defaults).then(Api.json);
  }

  /**
   * @return {Promise<Object>} - basic profile
   */
  getBasicProfile() {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    const endpoint = `${this.url}/v1/users/current/basicprofile`;

    return fetch(endpoint, this.defaults).then(Api.json);
  }

  /**
   * @param {String} - shopping cart session id
   * @return {Promise<Number>} - shopping cart items count
   */
  getShoppingCartItemsCount(shoppingCartSessionId) {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    if (!shoppingCartSessionId) {
      return Promise.reject(new Error('Shopping cart session id is required'));
    }

    const endpoint = `${this.url}/v3/shoppingcarts/${shoppingCartSessionId}/items/count`;

    return fetch(endpoint, this.defaults).then(Api.json);
  }

  /**
   * @param {String} email - users email
   * @return {Promise<Number>} - result
   */
  getExpiringProductsCount(email) {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    if (!email) {
      return Promise.reject(new Error('User email is required'));
    }

    const encoded = encodeURIComponent(email);
    const endpoint = `${this.url}/v2/purchaseoptions/${encoded}/renewalsandupgrades/stats`;

    return fetch(endpoint, this.defaults)
      .then(Api.json)
      .then((data) => Number(data.expiring));
  }

  /**
   * @return {Promise<Object>} - sku categories
   */
  getMySkuCategories() {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    const endpoint = `${this.url}/v2/stockkeepingunits/mine/categories`;

    return fetch(endpoint, this.defaults).then(Api.json);
  }

  /**
   * @return {Promise<Boolean>} result - true/false
   */
  getVirtualClassRoomSupport() {
    if (!this.available) {
      return Promise.reject(this.rejection);
    }

    const endpoint = `${this.url}/v1/lms/hasaccess`;

    return fetch(endpoint, this.defaults).then(Api.json);
  }
}

export default Api;
