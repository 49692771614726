import 'classlist-polyfill';
import 'custom-event-polyfill';
import closest from 'element-closest';
import 'core-js/features/promise/index.js';
import 'core-js/features/array/from.js';
import 'core-js/features/array/includes.js';
import 'core-js/features/object/assign.js';
import 'core-js/features/function/name.js';
import 'core-js/features/string/includes.js';
import 'core-js/features/string/starts-with.js';
import 'whatwg-fetch';
import init from './init.mjs';

closest(window);
init();
