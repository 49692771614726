'use strict';

const ESCAPE = 27;
const SUPPORT_TRANSITIONS = ('transition' in document.documentElement.style);

const fn = (f) => (typeof f === 'function');

const defaults = {
  hover: false,
  click: false,
  activeClass: 'TK-Dropdown--Active',
  beforeOpenHook: () => {},
  beforeCloseHook: () => {}
};

class Dropdown {
  constructor(element, options) {
    if (!element) {
      return;
    }

    this.element = element;
    this.options = Object.assign({}, defaults, options);

    this.closed = true;
    this.running = false;
    this.parent = this.options.parent || element.parentElement;
    this.button = this.options.button || this.parent.firstElementChild;
    this.beforeOpenHook = this.options.beforeOpenHook;
    this.beforeCloseHook = this.options.beforeCloseHook;

    this.init();
  }

  run() {
    if (!SUPPORT_TRANSITIONS) return;

    const { transition } = window.getComputedStyle(this.element, null);

    if (transition.split(' ')[0] === 'none') {
      this.running = false;
      return;
    }

    this.running = true;

    const transitionEndHandler = () => {
      this.running = false;
      this.element.removeEventListener('transitionend', transitionEndHandler, false);
    };

    this.element.addEventListener('transitionend', transitionEndHandler, false);
  }

  open() {
    if (!this.closed) return;

    if (fn(this.beforeOpenHook)) {
      this.beforeOpenHook();
    }

    this.run();
    this.element.classList.add(this.options.activeClass);
    this.closed = false;

    document.addEventListener('keydown', this.bind('closeOnEscape'), false);
  }

  close() {
    if (this.closed) return;

    if (fn(this.beforeCloseHook)) {
      this.beforeCloseHook();
    }

    this.run();
    this.element.classList.remove(this.options.activeClass);
    this.closed = true;

    document.removeEventListener('keydown', this.bind('closeOnEscape'), false);
  }

  closeOnEscape(event) {
    if (event.keyCode === ESCAPE) {
      this.close();
    }
  }

  toggle() {
    window.requestAnimationFrame(() => {
      this.closed ? this.open() : this.close();
    });
  }

  contains(node) {
    return this.element.contains(node);
  }

  associate(node) {
    let result = this.contains(node);

    if (node === this.button) {
      result = true;
    }

    return result;
  }

  /**
   * @param {String} method
   * @return {Function}
   */
  bind(method) {
    const bound = `${method}_`;

    if (!fn(this[bound])) {
      this[bound] = this[method].bind(this);
    }

    return this[bound];
  }

  on(eventType) {
    if (eventType === 'tk-click') {
      this.button.addEventListener('click', this.bind('toggle'), false);
    }

    if (eventType === 'tk-hover') {
      if (this.options.hover) {
        this.button.addEventListener('focus', this.bind('open'), false);
        this.parent.addEventListener('mouseenter', this.bind('open'), false);
        this.parent.addEventListener('mouseleave', this.bind('close'), false);
      }
    }

    if (eventType === 'tk-before-open-hook') {
      this.beforeOpenHook = this.options.beforeOpenHook;
    }

    if (eventType === 'tk-before-close-hook') {
      this.beforeCloseHook = this.options.beforeCloseHook;
    }
  }

  off(eventType) {
    if (eventType === 'tk-click') {
      this.button.removeEventListener('click', this.bind('toggle'), false);
    }

    if (eventType === 'tk-hover') {
      if (this.options.hover) {
        this.button.removeEventListener('focus', this.bind('open'), false);
        this.parent.removeEventListener('mouseenter', this.bind('open'), false);
        this.parent.removeEventListener('mouseleave', this.bind('close'), false);
      }
    }

    if (eventType === 'tk-before-open-hook') {
      this.beforeOpenHook = null;
    }

    if (eventType === 'tk-before-close-hook') {
      this.beforeCloseHook = null;
    }
  }

  deface() {
    if (!this.options.hover) return;

    const preventHandler = (event) => event.preventDefault();

    const on = () => {
      this.on('tk-click');
      this.button.removeEventListener('click', preventHandler, false);
    };

    const off = () => {
      this.off('tk-click');
      this.button.addEventListener('click', preventHandler, false);
    };

    const buttonHandler = () => {
      if (this.running) off();
      // window.setTimeout(on, 256);

      requestAnimationFrame(() => {
        off();
        on();
      });
    };

    this.button.addEventListener('mouseenter', buttonHandler, false);
  }

  init() {
    if (this.options.click) {
      this.on('tk-click');
    }
    if (this.options.hover) {
      this.on('tk-hover');
      this.deface();
    }
  }
}

export default Dropdown;
