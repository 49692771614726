/*
 * DropdownService holds a collection of
 * Dropdowns and operates on them as one
 * example ->
 * If you tell the service to close
 * it will close all associated dropdowns
 */

'use strict';

import Dropdown from './dropdown.mjs';

class DropdownService {
  /**
   * @param {HTMLElement[]|Dropdown[]} elements
   */
  constructor(elements, options) {
    if (!Array.isArray(elements)) {
      return;
    }

    this.dropdowns = elements.map((element) => {
      /**
       * If element is already initialized dropdown just return it
       */
      if (element instanceof Dropdown) {
        return element;
      }
      return new Dropdown(element, options);
    });
  }

  close() {
    this.dropdowns.forEach((dropdown) => {
      dropdown.close();
    });
  }

  contains(node) {
    let result = false;

    this.dropdowns.forEach((dropdown) => {
      if (dropdown.contains(node)) {
        result = true;
      }
    });

    return result;
  }

  associate(node) {
    let result = false;

    this.dropdowns.forEach((dropdown) => {
      if (dropdown.associate(node)) {
        result = true;
      }
    });

    return result;
  }

  on(eventType) {
    this.dropdowns.forEach((dropdown) => {
      dropdown.on(eventType);
    });
  }

  off(eventType) {
    this.dropdowns.forEach((dropdown) => {
      dropdown.off(eventType);
    });
  }
}

export default DropdownService;
