/**
 * data-match-exact-path
 * data-match-contains-search
 * data-match-starts-with-path
 * data-match-pattern
 */

const SELECTED_CLASS = 'TK-Item--Selected';
const DROPDOWN_SELECTOR = '.TK-Dropdown:not(.TK-Dash)';
const DROPDOWN_BUTTON_SELECTOR = '.TK-Menu-Item-Button, .TK-Aside-Menu-Button';
const TOP_LEVEL_SELECTED_LINKS = '.TK-Menu-Item-Link.TK-Item--Selected, .TK-Aside-Menu-Link.TK-Item--Selected';
const qsa = (selector, parent) => Array.from(parent.querySelectorAll(selector));

/**
 * Set selected state
 * @param {HTMLElement} parent - parent element of the links
 */
export function setSelectedState(parent) {
  qsa(`.${SELECTED_CLASS}`, parent).forEach((item) => item.classList.remove(SELECTED_CLASS));

  const { href, pathname, search, hostname } = window.location;
  const links = qsa('a', parent);

  links.forEach((link) => {
    const matchExact = link.hasAttribute('data-match-exact-path');
    const matchSearch = link.hasAttribute('data-match-contains-search');
    const matchStartsWith = link.hasAttribute('data-match-starts-with-path');
    const matchPattern = link.hasAttribute('data-match-pattern');

    if (matchExact && matchSearch) {
      if (pathname === link.pathname && search.indexOf(link.search) >= 0) {
        link.classList.add(SELECTED_CLASS);
      }
    }
    else if (matchExact) {
      if (pathname === link.pathname) {
        link.classList.add(SELECTED_CLASS);
      }
    }
    else if (matchStartsWith) {
      if (href.startsWith(link.href)) {
        link.classList.add(SELECTED_CLASS);
      }
    }

    if (matchPattern) {
      const regex = new RegExp(link.getAttribute('data-pattern'));
      if (regex.test(hostname + pathname)) {
        link.classList.add(SELECTED_CLASS);
      }
    }
  });

  const selectedLinks = qsa(`.${SELECTED_CLASS}`, parent);
  const topLevelSelectedLinks = qsa(`${TOP_LEVEL_SELECTED_LINKS}`, parent);

  if (topLevelSelectedLinks.length > 1) {
    const firstExactMatchLink = topLevelSelectedLinks.find(link => link.hasAttribute('data-match-exact-path'));

    if (firstExactMatchLink) {
      topLevelSelectedLinks
        .filter(link => link !== firstExactMatchLink)
        .forEach(link => link.classList.remove(SELECTED_CLASS));
    }
    else {
      topLevelSelectedLinks.slice(1).forEach(link => link.classList.remove(SELECTED_CLASS));
    }
  }

  if (topLevelSelectedLinks.length === 0) {
    let buttonMatched = false;

    selectedLinks.forEach((link) => {
      if (!buttonMatched) {
        const dropdown = link.closest(DROPDOWN_SELECTOR);
        if (dropdown) {
          const button = dropdown.parentElement.querySelector(DROPDOWN_BUTTON_SELECTOR);
          button && button.classList.add(SELECTED_CLASS);
          buttonMatched = true;
        }
      }
    });
  }
}
