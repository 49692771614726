/* eslint no-use-before-define: ["error", { "variables": false, "functions": false }] */

import Overlay from './overlay.mjs';
import Dropdown from './dropdown.mjs';
import DropdownService from './dropdown-service.mjs';
import { settled } from './all-settled.mjs';
import { initAccount } from './account.mjs';
import { setSelectedState } from './set-selected-state.mjs';
// import { initShoppingCartCounters } from './counters.mjs';
import { loadAndInitStickyElementIfNeeded } from './sticky-element.mjs';

// css link files
const METRIC_ID = '#js-tlrk-nav-metric';
const STYLES_ID = '#js-tlrk-nav-styles';

// nav dom selectors
const NAV_ID = '#js-tlrk-nav';
const DASH_ID = '#js-tlrk-nav-dash';
const DRAWER_ID = '#js-tlrk-nav-drawer';
const DRAWER_BUTTON_ID = '#js-tlrk-nav-drawer-button';
const OVERLAY_ID = '#js-tlrk-nav-overlay';

// mobile constants
const MOBILE_BREAKPOINT = 1240;
const TOUCH_DEVICE = !!('ontouchstart' in window || navigator.maxTouchPoints);

const preventDefault = (event) => event.preventDefault();
const isBelowBreakpoint = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= MOBILE_BREAKPOINT;
const isAboveBreakpoint = () => !isBelowBreakpoint();

const nav = document.querySelector(NAV_ID);

const qs = (selector) => nav && nav.querySelector(selector);
const qsa = (selector) => nav && Array.from(nav.querySelectorAll(selector));

/**
 * Init all dropdowns in navigation
 */
function initDropdowns() {
  if (!nav) {
    return;
  }

  const links = qsa('a, button');
  const lists = qsa('.TK-Dropdown');
  const dash = qs(DASH_ID);
  const drawer = qs(DRAWER_ID);
  const drawerButton = qs(DRAWER_BUTTON_ID);
  const overlayElement = qs(OVERLAY_ID);

  /**
   * Init overlay element
   */
  const overlay = new Overlay(overlayElement);

  /**
   * All visible desktop dropdowns proxy
   */
  const allDropdownsProxy = new DropdownService(lists, {
    click: true,
    hover: true,
    beforeOpenHook: () => {
      drawer && drawerMenu.close();
      allDropdownsProxy.close();
      isBelowBreakpoint() && overlay.show();
    },
    beforeCloseHook: () => {
      isBelowBreakpoint() && overlay.hide();
    }
  });

  /**
   * All dropdowns in drawer menu proxy
   */
  const inDrawerDropdownsProxy = new DropdownService(allDropdownsProxy.dropdowns.filter((dropdown) => drawer && drawer.contains(dropdown.element)));

  /**
   * Drawer Menu
   */
  const drawerMenu = new Dropdown(drawer, {
    click: true,
    hover: false,
    button: drawerButton,
    activeClass: 'TK-Drawer--Active',

    beforeOpenHook: () => {
      allDropdownsProxy.close();
      overlay.show();
      /**
       * Prevent drawer from closing when expanding inner dropdowns
       */
      inDrawerDropdownsProxy.off('tk-hover');
      inDrawerDropdownsProxy.off('tk-before-open-hook');
      inDrawerDropdownsProxy.off('tk-before-close-hook');
    },

    beforeCloseHook: () => {
      overlay.hide();
      inDrawerDropdownsProxy.on('tk-hover');
      inDrawerDropdownsProxy.on('tk-before-open-hook');
      inDrawerDropdownsProxy.on('tk-before-close-hook');
    }
  });

  /**
   * Prevent default on all buttons
   */
  if (TOUCH_DEVICE) {
    allDropdownsProxy.dropdowns
      .map((dropdown) => dropdown.button)
      .forEach((button) => button.addEventListener('click', preventDefault, false));
  }

  /**
   * Find elements that could gain focus handler
   * Close dropdowns when some of these link is focused
   */
  links
    .filter((link) => link !== overlay.element)
    .filter((link) => link !== drawerMenu.button)
    .filter((link) => !allDropdownsProxy.associate(link))
    .forEach((link) => link.addEventListener('focus', closeDropdownsWithDelay, false));

  /**
   * Add event handler on overlay
   */
  overlay.addEventListener('click', () => {
    drawerMenu.close();
    allDropdownsProxy.close();
  });

  /**
   * For some weird reason focus event is blocking click event from firing
   */
  function closeDropdownsWithDelay(delay = 128) {
    if (TOUCH_DEVICE) {
      return;
    }
    setTimeout(() => allDropdownsProxy.close(), delay);
  }

  /**
   * Resize handler
   */
  function toggleHover() {
    isAboveBreakpoint() ? allDropdownsProxy.on('tk-hover') : allDropdownsProxy.off('tk-hover');
  }

  /**
   * Add resize handler
   * @todo debounce/throttle
   */
  toggleHover();
  window.addEventListener('resize', toggleHover, false);

  /**
   * Add orientation change handler
   */
  window.addEventListener('orientationchange', () => {
    drawerMenu.close();
    allDropdownsProxy.close();
  }, false);

  /**
   * close dropdown in account spa
   */
  document.addEventListener('ng-location-changed', closeDropdownsWithDelay, false);

  /**
   * Set selected state
   */
  if (dash && drawer) {
    setSelectedState(dash);
    setSelectedState(drawer);
  }
}

/**
 * Try to prevent font flashing
 * Remove navigation loading class when css files are loaded
 */
function handleFonts() {
  let fired = false;
  const { fonts } = document; // modern fonts api
  const metric = document.querySelector(METRIC_ID);
  const styles = document.querySelector(STYLES_ID);
  const isLoaded = (link) => !link.classList.contains('is-loading');

  const onload = () => {
    if (fired) return;

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        nav.classList.remove('TK-Nav--Loading');
        nav.classList.add('TK-Nav--Loaded');
      });
    });

    fired = true;
  };

  // all browsers
  // with fast internet connection we will hit this case
  if (isLoaded(metric) && isLoaded(styles)) {
    return onload();
  }

  // modern browsers
  if (fonts) {
    // fast connection
    if (fonts.status === 'loaded') {
      return onload();
    }

    // slower connection
    const declarations = [
      '15px Metric-Light',
      '15px Metric-Medium',
      '15px Metric-Regular',
      '15px Metric-Semibold'
    ];

    const syncChecks = declarations.map((declaration) => fonts.check(declaration)); // Array<Boolean>
    const asyncChecks = declarations.map((declaration) => fonts.load(declaration)); // Array<Promise>

    if (syncChecks.every(Boolean)) {
      return onload();
    }

    // Promise - resolves when fonts are loaded of failed
    const ready = settled(asyncChecks);

    // race metric and other fonts loading
    return Promise.race ? Promise.race([ready, fonts.ready]).then(onload).catch(onload) : ready.then(onload);
  }

  // older browsers - IE11 and below
  const waitForCSS = (link) => new Promise((resolve, reject) => {
    if (isLoaded(link)) {
      resolve();
    }
    else {
      // these events may never fire
      // if fonts load during javascript parsing
      link.addEventListener('load', resolve);
      link.addEventListener('error', reject);

      /* eslint no-confusing-arrow: "off" */
      setTimeout(() => isLoaded(link) ? resolve() : reject(), 512);
    }
  });

  return settled([waitForCSS(metric), waitForCSS(styles)]).then(onload);
}

/**
 * Init skip to content
 */
function initSkipToContent() {
  const skipLink = qs('#js-tlrk-skip-link');
  const skipElement = document.createElement('div');
  skipElement.classList.add('TK-Nav-Skipper');
  skipElement.tabIndex = 0;
  skipLink.addEventListener('click', (event) => {
    nav.after(skipElement);
    skipLink.blur();
    skipElement.focus();
    skipElement.remove();
    event.preventDefault();
  });
}

/**
 * Init whole navigation
 */
function init() {
  if (window.TLRK_NAV_INITIALIZED) {
    return;
  }

  initAccount();
  initDropdowns();
  // NOTE Temporary disable counters and always point shopping cart link to shopping cart website
  // FIXME Integrate with new account API REST service to replace the OSC_SESSION cookie behaviour when ready
  // initShoppingCartCounters();

  // remove loading class
  requestAnimationFrame(handleFonts);

  loadAndInitStickyElementIfNeeded({
    top: 0,
    fixClass: 'TK-Bar--Sticky',
    selector: '#js-tlrk-nav .TK-Bar',
    breakpoint: MOBILE_BREAKPOINT
  });

  initSkipToContent();
  window.TLRK_NAV_INITIALIZED = true;
}

/**
 * Exports
 */
export default init;
