'use strict';

const defaults = {
  activeClass: 'TK-Nav-Overlay--Active'
};

export default class Overlay {
  constructor(element, options) {
    // Edge does not support object spread...
    this.options = Object.assign({}, defaults, options);
    this.element = element;
    this.activeClass = this.options.activeClass;
    this.hidden = true;
  }

  show() {
    if (!this.hidden || !this.element) return;
    this.element.classList.add(this.activeClass);
    this.hidden = false;
  }

  hide() {
    if (this.hidden || !this.element) return;
    this.element.classList.remove(this.activeClass);
    this.hidden = true;
  }

  addEventListener(event, listener, capture = false) {
    this.element && this.element.addEventListener(event, listener, capture);
  }
}
